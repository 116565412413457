<script setup lang="ts">
import { validateNotEmpty, validateEmail } from '@mop/shared/utils/validationRules';
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'ContactPage',
});

const route = useRoute();
const classNameRef = ref(['']);
const loadingRef = ref(false);
const { $mopConfig, $mopI18n, $gtm, $storyblokLivePreview } = useNuxtApp();
const { sendContactRequest, statusModelRef } = useMopContact();
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef: loadingCms } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage, getBreadcrumbSchema } = useMopSeo();
initTransition(classNameRef, loadingCms);
const notificationMessageRef = ref('');
const notificationTypeRef = ref('success');
const isSubmittedRef = ref(false);

const formStateRef = ref({
  formData: {
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  },
});

const rules = {
  validateNotEmpty: (value: any) => validateNotEmpty(value) || 'locale.validate.required',
  validateEmail: (value: string) => validateEmail(value) || 'locale.validate.email',
};

const subjects = [
  {
    text: $mopI18n.t('locale.contact.subject.apprenticeship'),
    value: $mopI18n.t('locale.contact.subject.apprenticeship'),
  },
  {
    text: $mopI18n.t('locale.contact.subject.traineeships'),
    value: $mopI18n.t('locale.contact.subject.traineeships'),
  },
  {
    text: $mopI18n.t('locale.contact.subject.professionals'),
    value: $mopI18n.t('locale.contact.subject.professionals'),
  },
  {
    text: $mopI18n.t('locale.contact.subject.general'),
    value: $mopI18n.t('locale.contact.subject.general'),
  },
  {
    text: $mopI18n.t('locale.contact.subject.my_application'),
    value: $mopI18n.t('locale.contact.subject.my_application'),
  },
  {
    text: $mopI18n.t('locale.contact.subject.other'),
    value: $mopI18n.t('locale.contact.subject.other'),
  },
  {
    text: $mopI18n.t('locale.contact.subject.consultant'),
    value: $mopI18n.t('locale.contact.subject.consultant'),
  },
];

const genders = [
  {
    text: $mopI18n.t('locale.contact.gender.male'),
    value: $mopI18n.t('locale.contact.gender.male'),
  },
  {
    text: $mopI18n.t('locale.contact.gender.female'),
    value: $mopI18n.t('locale.contact.gender.female'),
  },
  {
    text: $mopI18n.t('locale.contact.gender.diverse'),
    value: $mopI18n.t('locale.contact.gender.diverse'),
  },
];

async function handleSubmit() {
  loadingRef.value = true;
  formStateRef.value.formData.email = formStateRef.value.formData.email.trim();

  const payload = {
    ...formStateRef.value.formData,
    recipient: $mopConfig.getSupportEmail(),
    locale: $mopI18n.locale,
  };

  await sendContactRequest(payload);

  if (statusModelRef.value.hasError()) {
    notificationMessageRef.value = 'locale.contact.error';
    notificationTypeRef.value = 'alert';
  } else {
    notificationMessageRef.value = 'locale.contact.success';
    notificationTypeRef.value = 'success';
    isSubmittedRef.value = true;

    $gtm.trackEvent({
      eventName: 'contact_form',
      parameters: ['send', payload.subject],
    });
  }
  loadingRef.value = false;
}

useAsyncData(async () => {
  await getCmsStory('/contact');
  trackPageView('Contact');
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.contact.contact_name'),
      url: route.path,
    },
  ];

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(breadcrumbs),
  };
});

useHead(seoHeadRef);
</script>

<template>
  <div class="contact">
    <MopCmsContentElementsSlot
      id="contact__content-top"
      class="contact__content-top"
      :elements="cmsStoryModelRef.getContentElements('bodyTop')"
    />

    <UiNotification
      v-show="notificationMessageRef"
      :type="notificationTypeRef"
      :text="$mopI18n.t(notificationMessageRef)"
      class="contact__notification"
    />

    <UiForm v-if="!isSubmittedRef" class="contact__form" long @submit="handleSubmit">
      <UiFormGroup :label="$mopI18n.t('locale.contact.label.gender')" label-for="gender">
        <UiFormSelect
          id="gender"
          v-model="formStateRef.formData.gender"
          :items="genders"
          name="gender"
          :disabled-default-text="`-`"
        />
      </UiFormGroup>
      <UiFormGroup :label="$mopI18n.t('locale.contact.label.first_name')" label-for="first-name" required>
        <UiFormInput
          id="first-name"
          v-model="formStateRef.formData.firstName"
          name="first-name"
          :rules="[rules.validateNotEmpty]"
          type="text"
          autocomplete="given-name"
        />
      </UiFormGroup>

      <UiFormGroup :label="$mopI18n.t('locale.contact.label.last_name')" label-for="last-name" required>
        <UiFormInput
          id="last-name"
          v-model="formStateRef.formData.lastName"
          :rules="[rules.validateNotEmpty]"
          type="text"
          name="last-name"
          autocomplete="family-name"
        />
      </UiFormGroup>

      <UiFormGroup :label="$mopI18n.t('locale.contact.label.email')" label-for="email" required>
        <UiFormInput
          id="email"
          v-model="formStateRef.formData.email"
          :rules="[rules.validateNotEmpty, rules.validateEmail]"
          type="email"
          name="email"
          autocomplete="email"
        />
      </UiFormGroup>

      <UiFormGroup :label="$mopI18n.t('locale.contact.label.subject')" label-for="subject" required>
        <UiFormSelect
          id="subject"
          v-model="formStateRef.formData.subject"
          :items="subjects"
          :rules="[rules.validateNotEmpty]"
          name="subject"
          :disabled-default-text="`-`"
        />
      </UiFormGroup>

      <UiFormGroup :label="$mopI18n.t('locale.contact.label.message')" label-for="message" required>
        <UiFormTextArea
          id="message"
          v-model="formStateRef.formData.message"
          name="message"
          :rules="[rules.validateNotEmpty]"
        />
      </UiFormGroup>

      <UiFormGroup>
        <button
          :disabled="loadingRef"
          type="submit"
          :class="[
            'button',
            'button--primary',
            'contact__form-group-button',
            {
              'button--loading': loadingRef,
            },
          ]"
        >
          {{ $mopI18n.t('locale.submit') }}
        </button>
      </UiFormGroup>
    </UiForm>

    <MopCmsContentElementsSlot
      id="contact__content-bottom"
      class="contact__content-bottom"
      :elements="cmsStoryModelRef.getContentElements('bodyBottom')"
    />
  </div>
</template>

<style scoped lang="scss">
.contact__form {
  padding: 0 $global-padding;
  max-width: 1000px;
}

.contact__form-info {
  margin-bottom: $space30;
}

.contact__form-group-button {
  margin-left: columns(5, 20);
  margin-top: $space20;

  @include apply-upto(small) {
    margin-left: 0;
  }
}

.contact__notification {
  margin: 0 $global-padding;
}

.contact__content-top {
  margin-bottom: $space30;
}

.contact__content-bottom {
  margin: $space30 0;
}
</style>
