import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { StatusModelRef } from '@/types/status';
import { statusModel } from '@/models';

export default function useMopContact() {
  const { $apiCompanyAws } = useNuxtApp();
  const statusModelRef: StatusModelRef = ref(statusModel(null));

  async function sendContactRequest(params: any) {
    statusModelRef.value = statusModel(await $apiCompanyAws.sendContactRequest(params));
  }

  return securedWrap({
    sendContactRequest,
    statusModelRef,
  });
}
